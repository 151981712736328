import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  utilities: {
    title: 'Utilities',
    desc: 'Helpers to ease standard workflows.',
    routes: {
      aksmanager: {
        desc: 'Manage Azure Kubernetes Service',
        title: 'AKS Manager'
      },
      blobdownload: {
        desc: 'Download blobs from the LODB servers via URL or MessageID.',
        title: 'Blob Downloader'
      },
      encode: {
        desc: 'Encode a file into Base64 or HEX string.',
        title: 'Encode/Decode File'
      },
      invite: {
        desc: 'Send Azure Active Directory (AAD) invitations.',
        title: 'Invite AAD'
      },
      json: {
        desc: 'Stringify or parse a JSON document.',
        title: 'JSON parse/stringify'
      },
      msgdelete: {
        desc: 'Deleting entries from the transactional tables of the LODB.',
        title: 'Message Delete'
      },
      msgmove: {
        desc: 'Copy orders or messages from one environment to another.',
        title: 'Message Move'
      },
      syncGroup: {
        desc: 'Synchronize groups of database tables in the LODB between its different stages.',
        title: 'Syncgroups'
      },
      urlshortener: {
        desc: 'Transform long and complex URLs to readable and shareable short URLs.',
        title: 'URL Shortener'
      },
      xmlconvert: {
        desc: 'Convert XML code to LODB JSON.',
        title: 'XML Converter'
      },
      databaseaccess: {
        desc: 'Add or remove IP addresses to database connection whitelist',
        title: 'VP DB Access'
      },
      translations: {
        desc: 'Translate your Application',
        title: 'Translation Tool'
      },
      settingstooloe: {
        desc: 'Order Entry Settings',
        title: 'Settings Tool OE'
      }
    }
  }
};
var esES = {
  utilities: {
    title: 'Servicios',
    desc: 'Elementos auxiliares para facilitar los flujos de trabajo estándar.',
    routes: {
      aksmanager: {
        desc: 'Gestionar el servicio Azure Kubernetes',
        title: 'Gestor de AKS'
      },
      blobdownload: {
        desc: 'Descarga de blobs de los servidores LODB a través de URL o MessageID.',
        title: 'Descargador de blobs'
      },
      encode: {
        desc: 'Codificar un archivo en Base64 o en una cadena HEX.',
        title: 'Codificar/descodificar archivo'
      },
      invite: {
        desc: 'Enviar invitaciones de Azure Active Directory (AAD).',
        title: 'Invitar AAD'
      },
      json: {
        desc: 'Secuenciar o analizar un documento JSON.',
        title: 'Secuenciar/analizar JSON'
      },
      msgdelete: {
        desc: 'Borrado de entradas de tablas transaccionales de LODB.',
        title: 'Eliminación de mensaje'
      },
      msgmove: {
        desc: 'Copiar pedidos o mensajes de un entorno a otro.',
        title: 'Traslado de mensaje'
      },
      syncGroup: {
        desc: 'Sincronizar grupos de tablas de base de datos en la LODB entre sus diferentes etapas.',
        title: 'Grupos de sincronización'
      },
      urlshortener: {
        desc: 'Transforme las URL largas y complejas en URL cortas legibles y compartibles.',
        title: 'Abreviador de URL'
      },
      xmlconvert: {
        desc: 'Convertir código XML en LODB JSON.',
        title: 'Convertir XML'
      },
      settingstooloe: {
        desc: 'Configuración de entrada de pedidos',
        title: 'Settings Tool OE'
      }
    }
  }
};
var deDE = {
  utilities: {
    title: 'Werkzeuge',
    desc: 'Helfer zur Vereinfachung von Standard-Workflows.',
    routes: {
      blobdownload: {
        desc: 'Blobs von den LODB-Servern via URL oder MessageID herunterladen.'
      },
      encode: {
        desc: 'Beliebige Datei als Base64 oder HEX String codieren.'
      },
      json: {
        desc: 'Stringify oder parsen eines JSON Dokuments.'
      },
      msgdelete: {
        desc: 'Löschen von Einträgen aus den transaktionellen Tabellen der LODB.'
      },
      urlshortener: {
        desc: 'Konvertieren Sie lange und komplexe URLs in lesbare URLs.'
      },
      xmlconvert: {
        desc: 'XML-Code in LODB JSON konvertieren.'
      },
      translations: {
        desc: 'Übersetze deine Applikation',
        title: 'Übersetzungstool'
      },
      settingstooloe: {
        desc: 'Order Entry Einstellungen',
        title: 'Settings Tool OE'
      }
    }
  }
};
const UTILS_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { UTILS_TRANSLATIONS };
