import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'utilities',
  xmlconvert: 'xmlconvert',
  blobdownload: 'blobdownload',
  msgdelete: 'msg/delete',
  json: 'json',
  encode: 'encoder',
  invite: 'invite',
  sync: 'sync',
  aks: 'aks',
  msgmove: 'msg/move',
  urlshortener: 'urlshort',
  translations: 'translations',
  databaseaccess: 'databaseaccess',
  settingstooloe: 'settingstooloe'
};
const OE_SETTINGS_TOOL_CONFIG = {
  appId: 'oe',
  backendUrl: 'https://czv-oe-cap-settings-dev-weu.thankfulcoast-23154587.westeurope.azurecontainerapps.io',
  primaryIdentifier: {
    name: 'Custom',
    inputName: 'Stores',
    multiInput: true,
    isSalesOrgIdentifier: false
  },
  secondaryIdentifier: {
    name: 'Sales Org',
    inputName: 'Sales Org',
    isSalesOrgIdentifier: true
  }
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.utilities.title',
    description: 'external.utilities.desc',
    icon: 'zui-icon-service',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_utilities,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.blobdownload,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.blobdownload.title',
        description: `external.utilities.routes.blobdownload.desc`,
        icon: 'zui-icon-download',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['blobdownload']
        })
      }
    }, {
      path: ROUTE_PATHS.msgdelete,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.msgdelete.title',
        description: `external.utilities.routes.msgdelete.desc`,
        icon: 'zui-icon-delete',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['msg/delete']
        })
      }
    }, {
      path: ROUTE_PATHS.msgmove,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.msgmove.title',
        description: `external.utilities.routes.msgmove.desc`,
        icon: 'zui-icon-move-general',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['msg/move']
        })
      }
    }, {
      path: ROUTE_PATHS.json,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.json.title',
        description: `external.utilities.routes.json.desc`,
        icon: 'zui-icon-exchange',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['json']
        })
      }
    }, {
      path: ROUTE_PATHS.encode,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.encode.title',
        description: `external.utilities.routes.encode.desc`,
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['encode']
        })
      }
    }, {
      path: ROUTE_PATHS.xmlconvert,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.xmlconvert.title',
        description: `external.utilities.routes.xmlconvert.desc`,
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['xmlconvert']
        })
      }
    }, {
      path: ROUTE_PATHS.sync,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.syncGroup.title',
        description: `external.utilities.routes.syncGroup.desc`,
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['sync']
        })
      }
    }, {
      path: ROUTE_PATHS.aks,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.aksmanager.title',
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        description: 'external.utilities.routes.aksmanager.desc',
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['aks']
        })
      }
    }, {
      path: ROUTE_PATHS.urlshortener,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.urlshortener.title',
        description: `external.utilities.routes.urlshortener.desc`,
        icon: 'zui-icon-link-networks',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['urlshort']
        })
      }
    }, {
      path: ROUTE_PATHS.invite,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.invite.title',
        description: `external.utilities.routes.invite.desc`,
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['invite']
        })
      }
    }, {
      path: ROUTE_PATHS.translations,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.translations.title',
        description: 'external.utilities.routes.translations.desc',
        icon: 'zui-icon-edf-edf-active',
        showInMenu: true,
        hierarchy: 120,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['translations']
        })
      }
    }, {
      path: ROUTE_PATHS.databaseaccess,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.utilities.routes.databaseaccess.title',
        description: `external.utilities.routes.databaseaccess.desc`,
        icon: 'zui-icon-server-server-server-list',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_utilities,
          required: ['dbaccess']
        })
      }
    }, {
      path: ROUTE_PATHS.settingstooloe,
      canActivate: [AUTH_GUARD],
      loadChildren: () => import('@zeiss/ng-vis-settings-tool').then(m => m.SettingsToolModule.configure(OE_SETTINGS_TOOL_CONFIG)),
      data: {
        title: 'external.utilities.routes.settingstooloe.title',
        description: `external.utilities.routes.settingstooloe.desc`,
        icon: 'zui-icon-service',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.settingstool_applications,
          required: ['oe']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
